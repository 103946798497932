import React from 'react';

class Contract extends React.Component {
  constructor() {
    super()
    this.state = {
      file: null
    }
  }

  componentDidMount() {
    const array = [
      'this',
      'is',
      'an',
      'array',
      'of',
      'strings',
      'split',
      'from',
      'a',
      'smart',
      'contract',
      'file',
    ];
    this.setState({file: array})
  }

  render() {
    return <ul>
      {this.state.file && this.state.file.map((line, index) => <div key={index.toString()}>
        {line}
      </div>
      )}
    </ul>;
  }
}

export default Contract;
