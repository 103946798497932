import React from "react";
import Contract from "./pages/contract"
/* import firebase from "firebase/app";
import config from "../config";

firebase.initializeApp(config); */

export default () => (
  <>
    <h1>Welcome to Protocol Genius</h1>
    <p>Watch this space.</p>
    <Contract />
  </>
);
